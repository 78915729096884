import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UsersAPI from "../api";
import { Switch } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import SettingsAPI from "../api";
import { XMarkIcon } from "@heroicons/react/20/solid";

type Props = {
  open: boolean;
  setOpen: any;
  setShowSuccessUpdate: any;
  setSuccessContent: any;
};

const PasswordChangeModal = ({
  open,
  setOpen,
  setShowSuccessUpdate,
  setSuccessContent,
}: Props) => {
  const token = useSelector((state: any) => state.auth.token);
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const [userData, setUserData] = useState({
    password: "",
    newPassword: "",
  });

  const changePasswordHandler = async (e: any) => {
    e.preventDefault();
    setErrorMessage("");

    try {
      await SettingsAPI.changePassword(
        userData.password,
        userData.newPassword,
        token
      );

      setOpen(false);
      setShowSuccessUpdate(true);
      const successMessage = t("password_changed_description");
      setSuccessContent(successMessage);
      setUserData({
        password: "",
        newPassword: "",
      });
    } catch (err: any) {
      console.log(err);
      if (err.response.status === 400) {
        const errorMessage = t("invalid_password_message");
        setErrorMessage(errorMessage);
      } else if (err.response.status === 401) {
        const errorMessage = t("incorrect_password");
        setErrorMessage(errorMessage);
      } else {
        const errorMessage = t("server_error_message");
        setErrorMessage(errorMessage);
      }
    }
  };

  return open ? (
    <div className="fixed z-50 inset-0 overflow-y-auto bg-gray-500 bg-opacity-50">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:max-w-lg">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl">
            <XMarkIcon
              aria-hidden="true"
              className="h-6 w-6 ml-2 mt-2 cursor-pointer hover:text-gray-700"
              onClick={() => {
                setOpen(false);
              }}
            />
            <form
              className="p-6 space-y-6"
              action="#"
              method="POST"
              dir={i18n.language === "ar" ? "rtl" : "ltr"}
            >
              <div>
                <div className="mt-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("old_password")}
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="password"
                      required
                      value={userData.password}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          password: e.target.value,
                        })
                      }
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#60b0bd] focus:outline-none focus:ring-[#60b0bd] sm:text-sm"
                    />
                  </div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700 mt-2"
                  >
                    {t("new_password")}
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="password"
                      required
                      value={userData.newPassword}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          newPassword: e.target.value,
                        })
                      }
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#60b0bd] focus:outline-none focus:ring-[#60b0bd] sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  onClick={changePasswordHandler}
                  className="flex w-full justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 max-w-xl"
                >
                  {t("submit")}
                </button>
              </div>
            </form>
            {errorMessage && (
              <div className="bg-red-50 py-4 text-red-700 text-center">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PasswordChangeModal;
