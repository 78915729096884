import { useState } from "react";
import { useSelector } from "react-redux";
import UsersAPI from "../api";
type Props = {
    open: boolean;
    setOpen: any;
    userEmail: string;
};

enum EmailType {
    confirmation = 1,
}

const EmailModal = ({ open, setOpen, userEmail }: Props) => {
    const token = useSelector((state: any) => state.auth.token);
    const [emailType, setEmailType] = useState<EmailType>(1);
    const [errorMessage, setErrorMessage] = useState("");
    const [isConfirmationSent, setIsConfirmationSent] = useState(false);
    const [isResetSent, setIsResetSent] = useState(false);

    const sendConfirmationEmail = async (e: any) => {
        e.preventDefault();
        setErrorMessage("");
        if (!userEmail) {
            setErrorMessage("Please enter the user email!");
            return;
        }
        if (!emailType) {
            setErrorMessage("Please enter a valid discount amount!");
            return;
        }

        try {
            await UsersAPI.sendEmail(userEmail, emailType, token);
            setIsConfirmationSent(true);

            // setAmount("");
            // setOpen(false);
            // window.location.reload();
        } catch (err: any) {
            console.log(err);
            if (err.response.status === 404) {
                setErrorMessage("User not found!");
            } else if (err.response.status === 410) {
                setErrorMessage("user already confirmed his email");
            } else {
                setErrorMessage("ERROR!");
            }
        }
    };

    return open ? (
        <div className="fixed z-50 inset-0 overflow-y-auto bg-gray-500 bg-opacity-50">
            <div className="flex items-center justify-center min-h-screen px-4">
                <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:max-w-lg">
                    <div className="bg-white rounded-lg overflow-hidden shadow-xl">
                        <div
                            onClick={() => {
                                setOpen(false);
                            }}
                            className="mx-2 w-2 cursor-pointer"
                        >
                            x
                        </div>
                        <form
                            className="p-6 space-y-6"
                            action="#"
                            method="POST"
                            onChange={(e) => {
                                e.preventDefault();
                                setErrorMessage("");
                            }}
                        >
                            <label
                                htmlFor="amount"
                                className="block text-sm font-medium text-gray-700 flex gap-1"
                            >
                                Send Email to:
                                <p className="text-red-500">{userEmail}</p>
                            </label>
                            <div>
                                <button
                                    type="submit"
                                    onClick={sendConfirmationEmail}
                                    disabled={isConfirmationSent ? true : false}
                                    className={`${
                                        isConfirmationSent
                                            ? "bg-gray-400"
                                            : "bg-gray-600 hover:bg-gray-700"
                                    } flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 max-w-xl `}
                                >
                                    {isConfirmationSent
                                        ? "sent"
                                        : "confirmation email"}
                                </button>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                    }}
                                    className="flex w-full justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 max-w-xl"
                                >
                                    reset email
                                </button>
                            </div>
                        </form>
                        {errorMessage && (
                            <div className="bg-red-50 py-4 text-red-700 text-center">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default EmailModal;
