import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import DashboardAPI from "../api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

interface TutorialDialogProps {
    open: boolean;
    onClose: () => void;
}

const TutorialDialog: React.FC<TutorialDialogProps> = ({ open, onClose }) => {
    const token = useSelector((state: RootState) => state.auth.token);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const { i18n, t } = useTranslation();
    const images: any[] = [];

    if (isMobile) {
        for (let i = 0; i < 7; i++) {
            images.push(
                require(`../../../../../images/tutorial/Phone/${
                    i18n.language === "ar" ? "Ar" : "En"
                }${i + 1}.png`)
            );
        }
    } else {
        for (let i = 0; i < 6; i++) {
            images.push(
                require(`../../../../../images/tutorial/Laptop/${
                    i18n.language === "ar" ? "Ar" : "En"
                }${i + 1}.png`)
            );
        }
    }

    const handleNext = () => {
        if (currentImageIndex < images.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
        }
    };

    const handleBack = () => {
        if (currentImageIndex !== 0) {
            setCurrentImageIndex(currentImageIndex - 1);
        }
    };

    const handleClose = async () => {
        try {
            DashboardAPI.markTutorialAsViewed(token);
            onClose();
            setCurrentImageIndex(0); // Reset index when the dialog is closed
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <div className="m-0">
                <img
                    src={images[currentImageIndex]}
                    alt={`${currentImageIndex + 1}`}
                    className="w-full h-auto"
                />
            </div>
            <DialogActions>
                {currentImageIndex !== images.length - 1 ? (
                    <div
                        className="sm:px-6 px-2 w-full flex justify-between items-center sm:mb-4"
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    >
                        <div className="flex items-center gap-2">
                            <button
                                onClick={handleBack}
                                disabled={currentImageIndex === 0}
                                className={`rounded-md ${
                                    currentImageIndex === 0
                                        ? "bg-gray-100"
                                        : "bg-white hover:bg-gray-50"
                                } sm:px-3.5 px-2 sm:py-2.5 py-1.5 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 `}
                            >
                                {t("back")}
                            </button>
                            <button
                                onClick={handleNext}
                                color="primary"
                                className="rounded-md bg-white sm:px-3.5 px-2 sm:py-2.5 py-1.5 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                {t("next")}
                            </button>
                        </div>

                        <button
                            onClick={handleClose}
                            color="secondary"
                            className="text-gray-500 sm:text-sm text-xs"
                        >
                            {t("skip")}
                        </button>
                    </div>
                ) : (
                    <div className="sm:px-6 px-2 w-full flex justify-center items-center sm:mb-4">
                        <button
                            onClick={handleClose}
                            className="rounded-md bg-white sm:px-3.5 px-2 sm:py-2.5 py-1.5 sm:text-md text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            {t("get_started")}
                        </button>
                    </div>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default TutorialDialog;
