import axios from "axios";
import { AnalyticsResponse } from "../../../../types/analytics";

const AnalyticsAPI = {
    getUserStats: function (token: string, from: string, to: string) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/analytics/user-stats`,
            {
                from,
                to,
            },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }
        );
    },
    getTotalAnalytics: function (
        token: string,
        from: string,
        to: string,
        country: string | null = null,
        city: string | null = null,
        campaignId: string | null = null,
        gender: "male" | "female" | "all" | null = null,
        format: boolean | null = false
    ): Promise<AnalyticsResponse> {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/analytics/user-analytics`,
            {
                format,
                from,
                to,
                country,
                city,
                campaignId,
                gender,
            },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }
        );
    },
};

export default AnalyticsAPI;
