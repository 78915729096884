import axios from "axios";

const UsersAPI = {
  getAllUsers: function (token: string) {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/getAllUsers`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  getOneUser: function (token: string, userId: string) {
    return axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  increaseBalance: function (
    userEmail: string,
    amount: number,
    isBankTransfer: boolean,
    token: string
  ) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/payments/increase-balance`,
      { userEmail, amount, isBankTransfer },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },

  toggleTrust: function (userData: any, token: string) {
    return axios.patch(
      `${process.env.REACT_APP_API_URL}/users/updateuser`,
      { ...userData, isTrusted: !userData.isTrusted, userId: userData.id },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },
  updateDiscount: function (
    userEmail: string,
    discount: number,
    token: string
  ) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/users/update-discount`,
      { userEmail, discount },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },
  sendEmail: function (userEmail: string, emailType: 1 | 2, token: string) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/users/sendEmail`,
      { userEmail, emailType },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },
  userResendConfirmation: function (token: string) {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/users/resend-confirmation`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },
};

export default UsersAPI;
