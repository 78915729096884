const isValidHttpsFormat = (string: string): boolean => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
};

const isValidInternalUrl = (url: string): boolean => {
    const internalProtocol = "app://";

    // Check if the URL starts with the internal protocol
    return url.startsWith(internalProtocol);
};

export function isValidUrl(url: string): boolean {
    try {
        if (!url) {
            return true;
        }
        if (isValidHttpsFormat(url)) {
            return true;
        }

        if (isValidInternalUrl(url)) {
            return true;
        }

        return false;
    } catch (err) {
        return false;
    }
}
