import CampaignTableRow from "./CampaignTableRow";
import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

function formatNumber(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function dateFormater(date: Date, separator: string) {
  const day = date.getDate();
  // add +1 to month because getMonth() returns month from 0 to 11
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // show date and month in two digits
  // if month is less than 10, add a 0 before it
  let dayString = String(day);
  let monthString = String(month);
  if (day < 10) {
    dayString = "0" + day;
  }
  if (month < 10) {
    monthString = "0" + month;
  }

  // now we have day, month and year
  // use the separator to join them
  return dayString + separator + monthString + separator + String(year);
}

interface CampaignTableProps {
  campaigns: Campaign[];
}

type Campaign = {
  _id: string;
  title: string;
  userId: string;
  startDate: string;
  endDate: string;
  dailyBudget: string;
  country: string;
  targetedCities: string[];
  photoPath: string;
  link: string;
  status: string;
  createdAt: string;
  clicks: string;
  clickRate: string;
  views: string;
  moneySpent: string;
  user: string;
  // adminMessage: string;
  // servedCount: number;
  // pendingCount: number;
};

export default function CampaignsTable({ campaigns }: CampaignTableProps) {
  const token = useSelector((state: any) => state.auth.token);
  const [tableData, setTableData] = useState<Campaign[]>([]);

  const formatData = async (campaigns: Campaign[]) => {
    const data: Campaign[] = [];

    for (let i = 0; i < campaigns.length; i++) {
      const campaign: any = campaigns[i];
      data.push({
        ...campaign,
        createdAt: dateFormater(new Date(campaign.createdAt), "-"),
        startDate: dateFormater(new Date(campaign.startDate), "-"),
        endDate: dateFormater(new Date(campaign.endDate), "-"),
        dailyBudget: campaign.dailyBudget.toFixed(2),
        moneySpent: campaign.moneySpent.toFixed(2),
        views: campaign.views,
        clicks: campaign.clicks,
        status: campaign.status.toUpperCase(),
        clickRate:
          (((campaign.clicks / (campaign.views || 1)) * 100).toFixed(2) ||
            "0") + "%",
        user: campaign.userEmail,
      });
    }
    setTableData(data);
  };
  useEffect(() => {
    formatData(campaigns);
  }, [campaigns]);

  const columns = useMemo<MRT_ColumnDef<Campaign>[]>(
    () => [
      {
        accessorKey: "title", //access nested data with dot notation
        header: "Title",
        size: 150,
      },
      {
        accessorFn: (campaign) => {
          return campaign.user || "DELETED USER";
        },
        header: "User",
        size: 200,
      },
      {
        accessorKey: "createdAt", //normal accessorKey
        header: "Created At",
        size: 150,
      },
      {
        accessorKey: "startDate",
        header: "startDate",
        size: 150,
      },
      {
        accessorKey: "endDate",
        header: "endDate",
        size: 150,
      },
      {
        accessorKey: "dailyBudget",
        header: "Balance",
        size: 150,
      },
      {
        accessorKey: "moneySpent",
        header: "Spent",
        size: 150,
      },
      {
        accessorKey: "views",
        header: "Views",
        size: 150,
      },
      {
        accessorKey: "clicks",
        header: "Clicks",
        size: 150,
      },
      {
        accessorKey: "clickRate",
        header: "Click Rate",
        size: 150,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enablePagination: false,
    enableRowActions: true,
    renderRowActions: ({ row, table }) => (
      <Box>
        <IconButton onClick={() => console.log(row.original)}>
          <Link to={`/admin/dashboard/campaigns/${row.original._id}`}>
            <PencilSquareIcon className="h-4 w-4" />
          </Link>
        </IconButton>
      </Box>
    ),
  });
  return <MaterialReactTable table={table} />;
}
