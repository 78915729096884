import { useState } from "react";
import { useSelector } from "react-redux";
import UsersAPI from "../api";
import IUser from "../../../../../types/user";
import { Disclosure } from "@headlessui/react";
import { Navigate } from "react-router-dom";
import { AxiosError } from "axios";
type Props = {
    open: boolean;
    setOpen: any;
    userEmail: string;
};

const AddDiscountModal = ({ open, setOpen, userEmail }: Props) => {
    const token = useSelector((state: any) => state.auth.token);
    const [amount, setAmount] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState("");

    const addDiscountHandler = async (e: any) => {
        e.preventDefault();
        setErrorMessage("");
        if (!userEmail) {
            setErrorMessage("Please enter the user email!");
            return;
        }
        if (!amount || isNaN(Number(amount))) {
            setErrorMessage("Please enter a valid discount amount!");
            return;
        }
        if (Number(amount) > 100 || Number(amount) < 0) {
            setErrorMessage(
                "Please enter a discount amount between 0 and 100!"
            );
            return;
        }
        try {
            const discount = Number(amount) / 100;
            await UsersAPI.updateDiscount(userEmail.trim(), discount, token);
            setAmount("");
            setOpen(false);
            window.location.reload();
        } catch (err: any) {
            console.log(err);
            if (err.response.status === 404) {
                setErrorMessage("User not found!");
            } else {
                setErrorMessage("ERROR!");
            }
        }
    };

    return open ? (
        <div className="fixed z-50 inset-0 overflow-y-auto bg-gray-500 bg-opacity-50">
            <div className="flex items-center justify-center min-h-screen px-4">
                <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:max-w-lg">
                    <div className="bg-white rounded-lg overflow-hidden shadow-xl">
                        <div
                            onClick={() => {
                                setOpen(false);
                            }}
                            className="mx-2 w-2 cursor-pointer"
                        >
                            x
                        </div>
                        <form
                            className="p-6 space-y-6"
                            action="#"
                            method="POST"
                            onChange={(e) => {
                                e.preventDefault();
                                setErrorMessage("");
                            }}
                        >
                            <div>
                                <label
                                    htmlFor="amount"
                                    className="block text-sm font-medium text-gray-700 flex gap-1"
                                >
                                    Add Discount Percentage to:
                                    <p className="text-red-500">{userEmail}</p>
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="amount"
                                        name="amount"
                                        type="string"
                                        value={amount}
                                        onChange={(e) => {
                                            const amount = e.target.value;

                                            setAmount(amount);
                                        }}
                                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#60b0bd] focus:outline-none focus:ring-[#60b0bd] sm:text-sm max-w-xl"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    onClick={addDiscountHandler}
                                    className="flex w-full justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 max-w-xl"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                        {errorMessage && (
                            <div className="bg-red-50 py-4 text-red-700 text-center">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default AddDiscountModal;
