import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../../../utils/LoadingSpinner";
import logo from "../../../../../images/logos/hammeladslogo.png";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { isMobile } from "react-device-detect";
import WalletAPI from "../api";
import BankTransferSuccessDialog from "./BankTransferSuccessDialog";

type Props = {
    isOpen: boolean;
    setOpen: any;
};

function MobileFileInput({
    onFileSelect,
}: {
    onFileSelect: (file: File) => void;
}) {
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            onFileSelect(file);
        }
    };

    return (
        <input
            type="file"
            accept=".png, .jpg, .jpeg, .pdf"
            onChange={handleFileChange}
            className="hidden"
            id="mobile-file-input"
        />
    );
}

const BankTransferModal = ({ isOpen, setOpen }: Props) => {
    const token = useSelector((state: any) => state.auth.token);

    const [bankTransferInfo, setBankTransferInfo] = useState({
        name: "",
        validationDocumentName: "",
        validationDocumentUrl: "",
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { i18n, t } = useTranslation();

    const onDrop = useCallback((acceptedFiles: any) => {
        handleDocumentUpload(acceptedFiles[0]);
    }, []);
    const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
            "file/pdf": [".pdf"],
        },
        noClick: true,
    });

    const handleMobileFileSelect = (file: File) => {
        handleDocumentUpload(file);
    };

    useEffect(() => {
        console.log(bankTransferInfo);
    }, [bankTransferInfo]);

    const renderFileInput = () => {
        if (isMobile) {
            return (
                <div className="mt-1 sm:col-span-2 sm:mt-0 flex-col items-center justify-center">
                    <label
                        htmlFor="mobile-file-input"
                        className=" flex justify-between w-full rounded-md border-0 py-3.5 sm:px-10 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6 "
                    >
                        <p className="text-gray-500">{t("upload_document")}</p>
                        <p className="text-[10px] text-center text-gray-500 mt-1 ">
                            (jpg, png, pdf 4MB Max)
                        </p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 text-gray-400"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                            />
                        </svg>
                    </label>

                    <MobileFileInput onFileSelect={handleMobileFileSelect} />
                </div>
            );
        } else {
            // Render the desktop file input using react-dropzone
            return (
                <div
                    className="space-y-1 text-center "
                    style={{
                        minWidth: "200px",
                    }}
                    {...getRootProps()}
                >
                    {/* Add the invisible input */}
                    <input {...getInputProps()} style={{ display: "none" }} />

                    <div className="flex text-sm text-gray-600">
                        <label
                            className=" flex justify-between w-full rounded-md border-0 py-3.5 sm:px-10 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6 "
                            onClick={open}
                        >
                            <p className="text-gray-400">
                                {t("upload_document")}
                            </p>
                            <p className="text-[10px] text-center text-gray-500 ">
                                (jpg, png, pdf 4MB Max)
                            </p>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-gray-400"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
                                />
                            </svg>
                        </label>
                    </div>
                </div>
            );
        }
    };

    const handleDocumentUpload = async (document: File) => {
        console.log("docummmmm", bankTransferInfo);

        try {
            setErrorMessage("");
            setLoading(true);
            const formData = new FormData();
            formData.append("document", document);
            const response = await WalletAPI.uploadValidationDocument(
                formData,
                token
            );
            const path = response.data.data.documentPath;
            console.log(bankTransferInfo);
            setBankTransferInfo((prevInfo) => {
                return {
                    ...prevInfo,
                    validationDocumentUrl: path,
                    validationDocumentName: document.name,
                };
            });

            setLoading(false);
            console.log(path);
        } catch (err: any) {
            setLoading(false);
            if (
                err?.response?.data?.message
                    ?.toLowerCase()
                    ?.includes("size limit")
            ) {
                const message = t("invalid_document_size");
                setErrorMessage(message);
            }
        }
    };

    console.log(i18n.language);
    const sendToReviewHandler = async (e: any) => {
        e.preventDefault();
        try {
            setLoading(true);

            await WalletAPI.bankTransfer(
                token,
                bankTransferInfo.name,
                bankTransferInfo.validationDocumentUrl,
                bankTransferInfo.validationDocumentName
            );

            setSuccess(true);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    return success ? (
        <BankTransferSuccessDialog />
    ) : isOpen ? (
        <div className="fixed z-50 inset-0 overflow-y-auto bg-gray-500 bg-opacity-50">
            <div className="flex items-center justify-center min-h-screen px-4">
                <div className="fixed z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full sm:max-w-lg">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 absolute text-gray-500 top-1 right-1 cursor-pointer hover:text-gray-700"
                        onClick={() => setOpen(false)}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                    </svg>
                    <div className="bg-white rounded-lg overflow-hidden h-[620px] shadow-xl flex items-center justify-center py-2">
                        {loading ? (
                            <LoadingSpinner />
                        ) : (
                            <div className=" w-[100%] h-[100%] flex flex-col items-center">
                                <div className="flex flex-col items-center justify-center h-[34%] w-[100%] gap-2 border-b-2 border-gray">
                                    <img
                                        src={logo}
                                        alt="Website Logo"
                                        className="h-28 w-32 shadow-lg border-4 border-white rounded-xl"
                                    />
                                    <h2 className="font-bold">
                                        {t("bank_transfer_title").toUpperCase()}
                                    </h2>
                                </div>
                                <div className="h-[66%] w-[100%] py-6 px-16">
                                    <form
                                        className="flex flex-col gap-2"
                                        dir={
                                            i18n.language === "ar"
                                                ? "rtl"
                                                : "ltr"
                                        }
                                    >
                                        <legend className="text-sm text-gray-600">
                                            {t(
                                                "bank_transfer_description_message"
                                            )}
                                        </legend>
                                        <p className="font-bold text-sm">
                                            {`${t("account_number")}: `}
                                            <span className="bg-gray-50 rounded-xl px-1">
                                                {" "}
                                                282608014189541
                                            </span>
                                        </p>
                                        <p className="font-bold text-sm">
                                            {`${t("whatsapp_number")}: `}
                                            <span className="bg-gray-50 rounded-xl px-1">
                                                {" "}
                                                00966551407207
                                            </span>
                                        </p>

                                        <p className="font-bold text-sm">
                                            {`${t("iban")}: `}
                                            <span className="bg-gray-50 rounded-xl px-1">
                                                {" "}
                                                SA3880000282608014189541
                                            </span>
                                        </p>
                                        <p className=" text-xs">
                                            {`${t(
                                                "bank_transfer_sent_to_review_information_message"
                                            )}. `}
                                        </p>

                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="block w-full rounded-md border-0 py-3.5 sm:px-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-sm sm:leading-6"
                                            placeholder={`${t("name_on_card")}`}
                                            value={bankTransferInfo.name}
                                            onChange={(e) => {
                                                setBankTransferInfo((prev) => {
                                                    return {
                                                        ...prev,
                                                        name: e.target.value,
                                                    };
                                                });
                                            }}
                                        />
                                        {renderFileInput()}

                                        {bankTransferInfo.validationDocumentUrl && (
                                            <div className="flex items-center gap-1">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-7 h-7 text-gray-600"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                                                    />
                                                </svg>

                                                <h3 className="text-xs text-gray-600">
                                                    {
                                                        bankTransferInfo.validationDocumentName
                                                    }
                                                </h3>
                                            </div>
                                        )}

                                        <input
                                            type="submit"
                                            name="submit"
                                            id="submit"
                                            disabled={
                                                !bankTransferInfo.name ||
                                                !bankTransferInfo.validationDocumentUrl
                                            }
                                            onClick={sendToReviewHandler}
                                            value={`${t(
                                                "to_review"
                                            ).toUpperCase()}`}
                                            className={`block w-full rounded-md border-0 py-3 font-bold ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-500 sm:text-md sm:leading-6 bg-green-200 ${
                                                !!bankTransferInfo.name &&
                                                !!bankTransferInfo.validationDocumentUrl
                                                    ? "hover:bg-green-300 text-gray-800"
                                                    : "text-gray-400"
                                            } cursor-pointer`}
                                        />

                                        {!!errorMessage && (
                                            <p className="text-red-500 text-sm mx-1">
                                                {errorMessage}
                                            </p>
                                        )}
                                    </form>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default BankTransferModal;
