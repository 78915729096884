import { useEffect, useMemo, useState } from "react";
import IUser from "../../../../../types/user";
import AddBalanceModal from "./AddBalanceModal";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from "material-react-table";
import { useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import AddDiscountModal from "./AddDiscountModal";
import EmailModal from "./EmailModal";
import UsersAPI from "../api";

interface Props {
  users: IUser[];
}
function formatNumber(number: number) {
  return Number(
    number
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
}
function dateFormater(date: Date, separator: string) {
  const day = date.getDate();
  // add +1 to month because getMonth() returns month from 0 to 11
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // show date and month in two digits
  // if month is less than 10, add a 0 before it
  let dayString = String(day);
  let monthString = String(month);
  if (day < 10) {
    dayString = "0" + day;
  }
  if (month < 10) {
    monthString = "0" + month;
  }

  // now we have day, month and year
  // use the separator to join them
  return dayString + separator + monthString + separator + String(year);
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country?: string;
  balance: number;
  discount: number;
  isEmailVerified: string;
  isTrusted: string;
  createdAt: string;
  totalSpent: number;
  totalChargedByAdmin: number;
  totalChargedByUser: number;
};
export default function UsersTable({ users }: Props) {
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [selectedType, setSelectedType] = useState<string>("increase");
  const token = useSelector((state: any) => state.auth.token);
  const [user, setUser] = useState<any>(undefined);
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [tableData, setTableData] = useState<User[]>([]);
  const [balanceOpen, setBalanceOpen] = useState(false);
  const [discountOpen, setDiscountOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const formatData = async (users: IUser[]) => {
    const data: User[] = [];

    for (let i = 0; i < users.length; i++) {
      const user: any = users[i];

      data.push({
        id: user._id,
        createdAt: dateFormater(new Date(user.createdAt), "-"),
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        country: user?.country,
        balance: user.balance,
        discount: user.discount,
        isEmailVerified: String(user.isEmailConfirmed).toLowerCase(),
        totalSpent: user.totalSpent,
        totalChargedByAdmin: user.totalChargedByAdmin,
        totalChargedByUser: user.totalChargedByUser,
        isTrusted: user.isTrusted,
      });
    }
    setTableData(data);
  };
  useEffect(() => {
    formatData(users);
  }, [users]);

  const formatNumber = (number: number) =>
    number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: "createdAt",
        header: "Created At",
        size: 150,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 200,
      },

      {
        accessorFn(originalRow) {
          return `${originalRow.firstName} ${originalRow.lastName}`;
        },
        header: "Name",
        size: 150,
      },

      {
        accessorFn(originalRow) {
          return originalRow.isEmailVerified.toUpperCase();
        },

        header: "Email Verified",
        size: 200,
      },
      {
        accessorFn(originalRow) {
          return String(originalRow.isTrusted || false).toUpperCase();
        },

        header: "Trusted",
        size: 200,
      },
      {
        accessorFn(originalRow) {
          return originalRow?.country || "UKNOWN";
        },
        header: "Country",
        size: 150,
      },
      {
        accessorFn(originalRow) {
          return `${originalRow.discount * 100}%`;
        },
        header: "Discount",
        size: 150,
      },
      {
        accessorFn(originalRow) {
          return originalRow.totalSpent;
        },
        header: "Total Spent",
        size: 150,
      },

      {
        accessorFn(originalRow) {
          return originalRow.totalChargedByAdmin;
        },
        header: "Charged By Admin",
        size: 150,
      },
      {
        accessorFn(originalRow) {
          return originalRow.totalChargedByUser;
        },
        header: "Charged By User",
        size: 150,
      },
    ],
    []
  );

  const toggleTrust = async (user: User) => {
    try {
      console.log(user);
      await UsersAPI.toggleTrust(user, token);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enablePagination: false,
    enableRowActions: true,
    renderRowActions: ({ row, table }) => [
      <div className="flex gap-1">
        <button
          type="button"
          onClick={() => {
            setUserEmail(row.original.email);
            setBalanceOpen(true);
          }}
          className="rounded-md bg-gray-700 px-1 py-1 text-xs text-white shadow-xs hover:bg-gray-600"
        >
          BALANCE
        </button>
        <button
          type="button"
          onClick={() => {
            setUserEmail(row.original.email);
            setDiscountOpen(true);
          }}
          className="rounded-md bg-gray-700 px-1 py-1 text-xs text-white shadow-xs hover:bg-gray-600"
        >
          DISCOUNT
        </button>
        <button
          type="button"
          onClick={() => {
            setUserEmail(row.original.email);
            setEmailOpen(true);
          }}
          className="rounded-md bg-gray-700 px-1 py-1 text-xs text-white shadow-xs hover:bg-gray-600"
        >
          EMAIL
        </button>
        <button
          type="button"
          onClick={() => {
            toggleTrust(row.original);
          }}
          className="rounded-md bg-gray-700 px-1 py-1 text-xs text-white shadow-xs hover:bg-gray-600"
        >
          {row.original.isTrusted ? "UNTRUST" : "TRUST"}
        </button>
      </div>,
    ],
  });

  return (
    <>
      <AddBalanceModal
        open={balanceOpen}
        setOpen={setBalanceOpen}
        userEmail={userEmail}
      />
      <AddDiscountModal
        open={discountOpen}
        setOpen={setDiscountOpen}
        userEmail={userEmail}
      />
      <EmailModal
        open={emailOpen}
        setOpen={setEmailOpen}
        userEmail={userEmail}
      />
      <MaterialReactTable table={table} />
    </>
  );
}
