import axios from "axios";

const DashboardAPI = {
    markTutorialAsViewed: function (token: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/users/tutorial-viewed`,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }
        );
    },
};

export default DashboardAPI;
