import axios from "axios";

const WalletAPI = {
  getPaymentMethods: function (token: string) {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/payments/paymentMethods`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getPaymentURL: function (
    token: string,
    invoiceValue: number,
    paymentMethodId: number
  ) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/payments/getPaymentURL`,
      {
        invoiceValue,
        paymentMethodId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },

  checkPayment: function (token: string, paymentId: string) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/payments/checkPayment`,
      {
        paymentId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getTransactions: function (token: string) {
    return axios.get(`${process.env.REACT_APP_API_URL}/payments/transactions`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  },
  bankTransfer: function (
    token: string,
    name: string,
    validationDocumentUrl: string,
    validationDocumentName: string
  ) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/payments/bank-transfer`,
      {
        name,
        validationDocumentUrl,
        validationDocumentName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  },
  uploadValidationDocument: function (formData: FormData, token: string) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/payments/upload-validation-document`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getInvoice: function (token: string, invoiceId: string) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/payments/getInvoice`,
      {
        invoiceId,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    );
  },
  // executePaymentUsingCard: function (
  //     token: string,
  //     cardDetails: any,
  //     amount: number
  // ) {
  //     return axios.post(
  //         `${process.env.REACT_APP_API_URL}/payments/newdirectpayment`,
  //         {
  //             cardDetails,
  //             amount,
  //         },
  //         {
  //             headers: {
  //                 authorization: `Bearer ${token}`,
  //             },
  //         }
  //     );
  // },
  // executePaymentUsingToken: function (
  //     token: string,
  //     paymentToken: string,
  //     amount: number
  // ) {
  //     return axios.post(
  //         `${process.env.REACT_APP_API_URL}/payments/directpayment`,
  //         {
  //             token: paymentToken,
  //             amount,
  //         },
  //         {
  //             headers: {
  //                 authorization: `Bearer ${token}`,
  //             },
  //         }
  //     );
  // },
  // removePaymentMethod: function (token: string, cardToken: string) {
  //     return axios.delete(
  //         `${process.env.REACT_APP_API_URL}/payments/paymentmethods`,
  //         {
  //             headers: {
  //                 authorization: `Bearer ${token}`,
  //             },
  //             data: {
  //                 token: cardToken,
  //             },
  //         }
  //     );
  // },
};

export default WalletAPI;
