import { Chart, ChartData, registerables } from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { AnalyticsChartData } from "../../../../../types/analytics";
import { formatDateToLabel } from "../../../shared/utils/analytics";
Chart.register(...registerables);

interface Props {
    data: AnalyticsChartData;
}
function ViewsLineChart({ data }: Props) {
    const [chartData, setChartData] = useState<ChartData>({
        labels: [],
        datasets: [],
    });

    useEffect(() => {
        setChartData({
            labels:
                data.labels.map((label: string) =>
                    formatDateToLabel(label)
                ) || [],
            datasets: [
                {
                    label: "Number Of Views",
                    data: data.datasets,
                    borderColor: "#6366f1",
                    backgroundColor: "transparent",
                    pointBorderColor: "transparent",
                    pointBackgroundColor: "#6366f1",
                    pointBorderWidth: 8,
                    borderWidth: 7,
                    fill: false,
                    tension: 0.5,
                    pointRadius: 7,
                },
            ],
        });
    }, [data]);

    const options = {
        scales: {
            x: {
                ticks: {
                    display: false,
                    font: {
                        size: 10,
                    },
                },
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {},
                ticks: {
                    precision: 0,
                },
                beginAtZero: true,
            },
        },

        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return <Line data={chartData as any} options={options} />;
}

export default ViewsLineChart;
